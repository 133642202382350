<template>
  <v-card flat>
    <order-item id="supplier" :isList="true" type="supplier" v-if="renderScrollList" :payload="scrollListPayload"></order-item>
  </v-card>
</template>
<script>
import { hostAppApi } from '@/plugins/axios_settings'
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      listOfOrders: [],
      scrollListPayload: {
        width: 65,
        loading: false,
        module: '',
        moduleTitle: 'message.whsDashboard.receiveSupplierOrder',
        showScanCode: true,
        list: [],
        totalCount: 0,
        items: [{
          value: 'number',
          class: 'pa-0 my-auto',
          cols: '3',
          spanClass: 'body-2',
          isParagraph: true
        }, {
          value: '',
          cols: '7',
          class: 'pa-0',
          rowClass: 'mt-2',
          subItems: [{
            value: 'suppliername',
            class: 'pa-0',
            cols: '12',
            spanClass: 'subtitle'
          }, {
            value: 'orderdate',
            cols: '7',
            class: 'pa-0'
          }, {
            value: 'receivedqty',
            class: 'pa-0 text-end pr-2',
            cols: '5'
          }]
        }],
        hasDelete: false,
        page: -1,
        previousSearch: '',
        recordsPerPage: 15
      },
      loading: false,
      page: 0,
      totalCount: 0,
      totalDbData: 0,
      scanNumber: '',
      perPage: 6,
      hasNoNext: true,
      /* Scanner field */
      scannerPayload: {
        placeholder: 'message.whsDashboard.scanText',
        change: this.getRespectiveSupplierOrder,
        attrRef: 'scannerFieldFocus',
        attrId: 'scannerField'
      },
      scannerFieldLoading: false,
      renderScrollList: false,
      listMaxCallLoaded: false
    }
  },
  components: {
    'order-item': () => import('@/components/Order/NewOrderItem.vue')
    // 'barcode-scanner': () => import('@/components/BarQrCodeScanner')
  },
  mounted () {
    this.getOrders()
    this.$eventBus.$on('loadList', (data) => {
      this.getOrders(data)
    })
    // requestAnimationFrame(() => {
    //   if (this.$refs.scanFocus) {
    //     this.$refs.scanFocus.focus()
    //   }
    // })
    // this.getOrders()
  },
  watch: {
    page (val) {
      this.getOrders()
    }
  },
  computed: {
    ...mapGetters(['getHostRefApi', 'userId'])
    // getPaginateData () {
    //   var showFrom = ((this.page - 1) * this.perPage) + 1
    //   var showTo = (showFrom - 1) + this.listOfOrders.length
    //   return this.totalDbData > 0 ? `${showFrom} - ${showTo} av ${this.totalDbData}` : ''
    // }
  },
  methods: {
    getOrders (search) {
      // this.loading = true
      // const pageVal = this.page || 1
      // const query = {
      //   page: pageVal,
      //   rowsperpage: this.perPage
      if (search && (search !== this.scrollListPayload.previousSearch)) {
        this.scrollListPayload = { ...this.scrollListPayload, ...{ list: [], page: -1, previousSearch: search, totalCount: 0 } }
        this.listMaxCallLoaded = false
      }
      // hostAppApi.post(`${this.getHostRefApi}warehouseorders/get_supplierorder_with_qty?user_id=${this.userId}`, query)
      if (!search && this.scrollListPayload.previousSearch) {
        this.scrollListPayload = { ...this.scrollListPayload, ...{ list: [], page: -1, previousSearch: '', totalCount: 0 } }
        this.listMaxCallLoaded = false
      }
      if (this.listMaxCallLoaded) return
      this.scrollListPayload.loading = true
      this.scrollListPayload.module = this.MODULE
      this.scrollListPayload.page++
      const model = {
        page: this.scrollListPayload.page + 1,
        rowsperpage: this.scrollListPayload.recordsPerPage
      }
      let url = `${this.getHostRefApi}warehouseorders/get_supplierorder_with_qty?user_id=${this.userId}&only_items=1`
      if (!this.scrollListPayload.showScanCode && search) url += `&search=${search}`
      hostAppApi.post(url, model)
        .then((response) => {
          if (response.data) {
            // const { items, total } = response.data
            // this.totalDbData = total
            // this.totalCount = Math.ceil(total / this.perPage)
            // this.hasNoNext = this.page === this.totalCount
            // this.listOfOrders = items
            // const { data, projectFullName } = response.data
            this.scrollListPayload.total = response.data.total
            // const transformData = {
            //   date: { list: ['deadline'], from: 'YYYY-MM-DD', to: 'DD.MM.YYYY' }
            // }
            // data = this.$formatter.formatListModel(data, transformData)
            this.scrollListPayload.list = [...this.scrollListPayload.list, ...response.data.items]
            // if (data.length) this.scrollListPayload.totalCount = response.data.total
            // if (this.scrollListPayload.totalCount === this.scrollListPayload.list.length) this.listMaxCallLoaded = true
            // this.printProjectName = projectFullName
            if (this.scrollListPayload.totalCount === this.scrollListPayload.list.length) this.listMaxCallLoaded = true
          }
        })
        .finally(() => {
          this.loading = false
          this.scrollListPayload.loading = false
          if (this.scrollListPayload.page === 0) this.renderScrollList = true
        })
    // },
    // previousList () {
    //   this.page--
    //   this.checkIsNextEnabled()
    // },
    // nextList () {
    //   if (this.page === this.totalCount) this.hasNoNext = true
    //   else {
    //     this.page++
    //     this.hasNoNext = false
    //   }
    // },
    // checkIsNextEnabled () {
    //   if (this.page === this.totalCount) this.hasNoNext = true
    //   else {
    //     this.hasNoNext = false
    //   }
    // },
    // getRespectiveSupplierOrder () {
    //   if (this.scanNumber) {
    //     this.scannerFieldLoading = true
    //     hostAppApi.get(`${this.getHostRefApi}warehouseorders/get_order_by_number/${this.scanNumber}?type=supplier`)
    //       .then((response) => {
    //         if (response && response.data) {
    //           this.$router.push(`/supplier_orders/${response.data}`)
    //         } else {
    //           this.$root.$emit('snackbar', { snackbar: true, color: 'info', text: 'message.order.noOrderFound' })
    //         }
    //       })
    //       .finally(() => { this.scannerFieldLoading = false })
    //   }
    }
  }
}
</script>
